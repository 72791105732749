import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    maxWidth: '400px',
  },
  otherTextBox: {
    maxWidth: '500px',
    '& .MuiTypography-body1': {
      margin: '15px 6px 10px 0',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '300',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#b2b2b4',
    },
    '& .MuiOutlinedInput-multiline': {
      height: '65px',
      margin: '10px 39px 20px 0',
      borderRadius: '5px',
      width: '100%',
      padding: '10px',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100%',
    },
  },
  autoComplete: {
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap !important',
    },
  },
  icon: {
    '& .Mui-checked': {
      color: `${theme.palette.primary.dark} !important`,
    },
    '& .MuiCheckbox-root': {
      color: '#dddde2',
    },
  },
  selectInput: {
    '& .MuiMenu-paper': {
      maxHeight: 400,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  formWrapper: {
    fontFamily: 'Montserrat',
    paddingLeft: '0px',
    paddingRight: '0px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '& .MuiTypography-h6': {
      fontSize: '18px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#282828',
    },
  },
  radioBtn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
  },
  helperText: {
    color: theme.palette.error.dark,
    marginLeft: 0,
  },
  programTime: {
    '& .Mui-checked': {
      color: `${theme.palette.primary.dark} !important`,
    },
    '& .MuiRadio-root': {
      color: 'rgb(0,0,0,0.3)',
    },
  },
  required: {
    color: 'red',
  },

  formHeader: {
    padding: '20px 0px',
    '& .MuiTypography-h3': {
      maxWidth: '435px',
      fontFamily: 'Montserrat',
      fontSize: '30px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#282828',
    },
    '& .MuiTypography-body1': {
      fontSize: '18px',
      fontWeight: '300',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#494949',
    },
  },
  schoolInfo: {
    '& h6': {
      fontFamily: 'Montserrat',
      padding: '20px 0px',
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '4.5px 14px',
    },
    '& .MuiFormControl-root': {
      marginTop: '15px !important',
    },
    '& .MuiOutlinedInput-root': {
      maxWidth: '400px',
      height: '29px',
    },
  },
  contactInfo: {
    '& .MuiFormControl-root': {
      marginTop: '15px !important',
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '4.5px 14px',
    },
    '& .MuiOutlinedInput-root': {
      maxWidth: '400px',
      height: '29px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
    },
    '& h4': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        marginBottom: '-10px',
      },
    },
    '& span': {
      fontSize: '11px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '7px',
      },
    },
  },
  bottomField: {
    padding: '25px 0px',
    '& .PrivateNotchedOutline-root-34': {
      border: 'solid 1px #dddde2',
    },
  },
  textArea: {
    width: '100%',
    '& .MuiInputBase-root': {
      maxWidth: '600px',
      height: '85px',
    },
  },
  preference: {
    '& .MuiTypography-caption': {
      fontSize: '13px !important',
      color: 'rgb(0,0,0,0.3) !important',
    },
    padding: '30px 0px',
    '& h6': {
      padding: '20px 0px',
    },
    '& ul': {
      paddingLeft: '0px',
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& li': {
      cursor: 'pointer',
      fontSize: '13px',
    },
  },
  demographics: {
    padding: '30px 0px',
    '& h6': {
      padding: '20px 0px',
    },
    '& ul': {
      paddingLeft: '0px',
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& li': {
      fontSize: '13px',
      cursor: 'pointer',
    },
  },
  selectedEl: {
    backgroundColor: '#fff !important',
    border: `1px solid ${theme.palette.primary.dark} !important`,
    boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
  },
  list: {
    display: 'flex',
    listStyle: 'none',
    userSelect: 'none',
    '& li': {
      margin: '3px 10px 3px 0px',
      border: '1px solid #fff',
      background: 'rgb(0,0,0,0.1)',
      padding: '5px',
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: '300',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#282828',
    },
  },
  prevContent: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: 'smaller',
      marginLeft: '3px',
    },
  },
  chipsWrapper: {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar ': {
      width: '0px',
      background: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '60px',
    },
  },
  chips: {
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0px 12px',
    marginTop: '10px',
    '& button': {
      backgroundColor: theme.palette.primary.dark,
      paddingRight: '8px',
      fontWeight: '600',
      color: 'white',
      margin: '5px',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        '& .close': {
          visibility: 'visible',
        },
      },
    },
    '& .close': {
      visibility: 'hidden',
      [theme.breakpoints.down('sm')]: {
        visibility: 'visible',
      },
    },
  },
  chipContent: {
    display: 'flex',
    gap: '11px',
  },
  sideContentHeader: {
    height: 'calc(100% - 227px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '& .MuiButton-outlined': {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    '& .add-more': {
      color: theme.palette.primary.dark,
    },
    '& .MuiTypography-h6': {
      fontSize: '18px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#282828',
      fontFamily: 'Montserrat',
    },
    '& button': {
      marginTop: '5px',
    },
  },
  mainImage: {
    '& img': {
      height: '40px',
      [theme.breakpoints.down('xs')]: {
        height: '20px',
      },
    },
  },
  lineBreak: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  sideContent: {
    position: 'sticky',
    top: '64px',
    backgroundColor: '#cddbcf',
    display: 'flex',
    height: 'calc(100vh - 64px)',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 64px)',
    },
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  closeButton: {
    width: '100%',
    justifyContent: 'end',
    '& span': {
      justifyContent: 'end',
    },
    '& svg': {
      top: '0px',
      position: 'absolute',
      right: '-19px',
      fontSize: '29px',
    },
    '& .MuiButton-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  sideTitle: {
    textAlign: 'center',
  },
  submitBtn: {
    textAlign: 'center',
    marginBottom: '15px',

    '& button': {
      fontFamily: 'Montserrat-bold',
      width: '100%',
      backgroundColor: '#fff',
      color: theme.palette.primary.dark,
      lineHeight: '33px',
    },
    '& .MuiDivider-light': {
      backgroundColor: '#fff',
    },
    '& p': {
      padding: '28px 0px',
      fontSize: '16px',
      fontWeight: '300',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#282828',
    },
  },
}));
export default useStyles;
