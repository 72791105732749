import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';

const Header = () => {
  const classes = useStyles();
  return (
    <Container className={classes.headerWrapper} maxWidth="xl">
      <div>
        <Grid container={true} justifyContent="flex-start" className={classes.header}>
          <div className={classes.mainImage}>
            <img alt="afterSchool logo" src="/ahq-logo-2.png" />
          </div>
        </Grid>
        <hr className={classes.lineBreak} />
      </div>
    </Container>
  );
};

export default Header;
