import { Container, Grid, Typography, TextField, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useStyles from './styles';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import cs from 'classnames';
import useActivityStore from '../../../store/activityStore';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';

const formData = [
  {
    id: 1,
    title: 'Grades',
    required: true,
    options: [
      'Pre-K',
      'Kindergarten',
      '1st',
      '2nd',
      '3rd',
      '4th',
      '5th',
      '6th',
      '7th',
      '8th',
      '9th',
      '10th',
      '11th',
      '12th',
    ],
  },
  {
    id: 2,
    title: 'Gender Restrictions',
    required: false,
    options: ['Girls Only', 'Boys Only'],
    isSingleSelect: true,
  },
  {
    id: 3,
    title: 'Service Types',
    textBoxOptions: [{ optionIndex: 6, optionText: 'Other Service Types' }],
    OtherOptionText: 'Other Service Types',
    required: true,
    options: ['Camps', 'Classes', 'Curriculum', 'Events', 'Workshops', 'Mentoring', 'Other'],
  },
  {
    id: 4,
    title: 'Timing',
    required: true,
    options: ['Before-school', 'After-school', 'During-school', 'Evenings', 'Weekends', 'Overnight'],
  },
  {
    id: 5,
    title: 'What days of the week do you want programs for?',
    required: true,
    options: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  },
  {
    id: 6,
    title: 'Pricing Options',
    required: true,
    options: ['Free to parents', 'Scholarships available', 'Parents pay for programs'],
  },
  {
    id: 7,
    title: 'Special Factors',
    required: false,
    textBoxOptions: [{ optionIndex: 2, optionText: 'Other Special Factors' }],
    OtherOptionText: 'Other Special Factors',
    options: ['Virtual Programs', 'COVID Protections', 'Other'],
  },
  {
    id: 8,
    title: 'Does your school have a budget for after-school programs?',
    required: true,
    options: ['Yes', 'No', "I'm not sure"],
    isSingleSelect: true,
  },
  {
    id: 9,
    title: 'How have parents and students registered for extracurricular activities at your school?',
    required: true,
    textBoxOptions: [
      {
        optionIndex: 4,
        optionText: 'Other ways parents or students registered for extracurricular activities at your school?',
      },
      { optionIndex: 1, optionText: 'What software has been used to take registrations online?' },
    ],
    OtherOptionText: 'Other ways parents or students registered for extracurricular activities at your school? ',
    options: [
      'Paper registration forms',
      'Online registration system',
      'Emailing a teacher or staff member',
      'Calling the school',
      'Other',
    ],
  },
  {
    id: 10,
    title: 'What are some challenges that make it hard for students to participate in programs',
    required: true,
    textBoxOptions: [
      { optionIndex: 6, optionText: 'Other challenges that make it hard for students to participate in programs' },
    ],
    OtherOptionText: 'Other challenges that make it hard for students to participate in programs',
    options: [
      'Too hard to register',
      'No easy way to know what programs are available',
      'Submission deadlines are missed',
      "Programs aren't affordable",
      'Lack of transportation',
      'Lack of interest',
      'Other',
    ],
  },
  {
    id: 11,
    title:
      'Would an online directory of activities and enabling online registration increase the likelihood of students participating in enrichment programs?',
    required: true,
    options: ['Yes', 'No', 'Possibly'],
    isSingleSelect: true,
  },
];

const ProgramForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    errorMessage,
    selected,
    selectedOptions,
    setSelectedOptions,
    inputErrorState,
    setInputErrorState,
    inputDetails,
    setInputDetails,
    handleFormDataSubmit,
    getActivityTypes,
    activityStates,
    success,
    allSubCategories,
    setAllSubCategories,
    setActiveIndex,
    setSelected,
  } = useActivityStore();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [otherFields, setOtherFields] = useState({});
  const [open, setOpen] = useState(!!errorMessage);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const sortedStates = activityStates.sort((a, b) => (a.name > b.name ? 1 : -1));
  const handleSubmit = () => {
    let updatedOptions = {};

    const allSubCategoriesClone = allSubCategories.map((sub) => sub.id);
    const allUniqueSubCategories = [...new Set(allSubCategoriesClone)];
    selectedOptions.forEach((option) => {
      const { formId, optionId } = option;
      const { title, options, isSingleSelect = false } = formData.find((fd) => fd.id === formId);
      if (isSingleSelect) {
        updatedOptions[title] = options[optionId];
      } else {
        updatedOptions[title] = optionId.map((opt) => options[opt]);
      }
    });
    let updatedSubmitData = {
      'Programs Desired': allUniqueSubCategories,
      'Organization Name': inputDetails.schoolName,
      'Organization City': inputDetails.schoolCity,
      'Organization State': [inputDetails.schoolState],
      'Number of Students Served': +inputDetails.numberOfStudents,
      'Contact Name': inputDetails.name,
      'Contact Phone': inputDetails.phoneNumber,
      'Contact Email': inputDetails.email,
      'When do you plan to implement these programs': inputDetails.radioButtonValue,
      'How many students do you want to serve with after-school programs': +inputDetails.studentsServed,
      'Notes': inputDetails.additionalComment,
      ...otherFields,
      ...updatedOptions,
    };
    handleFormDataSubmit(updatedSubmitData);
  };
  const checkIfRequiredInputFilled = () => {
    const getRequiredInputState = Object.keys(inputErrorState).map((key) => inputErrorState[key]);
    const isAllInputFilled = getRequiredInputState.some((el) => el === true);
    const inputDetailsClone = { ...inputDetails };
    delete inputDetailsClone.additionalComment;
    delete inputDetailsClone.phoneNumber;
    const isInputDetailsAreFilled = Object.keys(inputDetailsClone)
      .map((key) => !!inputDetailsClone[key])
      .includes(false);
    return isAllInputFilled || isInputDetailsAreFilled;
  };
  const checkIfRequiredOptionSelected = () => {
    const requiredOptionsId = formData.filter((element) => element.required === true).map((ele) => ele.id);
    const selectedOptionsId = selectedOptions.filter((obj) => obj.optionId.length !== 0).map((ele) => ele.formId);
    const result = requiredOptionsId.map((formId) => selectedOptionsId.includes(formId));
    const allSelected = result.every((el) => el === true);
    return allSelected;
  };
  useEffect(() => {
    const isAllInputsAreFilled = checkIfRequiredInputFilled();
    const isSubmitBtnDisabled = checkIfRequiredOptionSelected();
    setDisableSubmit(!isSubmitBtnDisabled || isAllInputsAreFilled);
  });
  useEffect(() => {
    getActivityTypes();
  }, []);

  useEffect(() => {
    if (success) {
      history.push('/activity-request/success');
    }
    if (errorMessage !== '') {
      setOpen(!!errorMessage);
    }
  }, [success, errorMessage]);

  const checkIfOptionIsSelected = (formId, optionId) => {
    const selectedOptionsArray = selectedOptions.find((formOption) => formOption.formId === formId)?.optionId;
    if (!!selectedOptionsArray) {
      return selectedOptionsArray.includes(optionId);
    }
    return false;
  };
  const handleFormSelection = (formId, optionId) => () => {
    const index = selectedOptions.findIndex((data) => data.formId === formId);
    if (index > -1) {
      const currentFormData = formData.find((fd) => fd.id === formId);
      const getSelectVal = selectedOptions[index];
      const removeOld = selectedOptions.filter((data) => data.formId !== formId);
      if (currentFormData.isSingleSelect) {
        if(currentFormData.required){
          setSelectedOptions([...removeOld, { formId, optionId: [optionId] }]);
        }else {
          const selectedPrevValue = selectedOptions.find((data) => data.formId === formId);
          if (selectedPrevValue?.optionId[0] === optionId){
            setSelectedOptions([...removeOld,{ formId, optionId: [] }]);
          }else {
            setSelectedOptions([...removeOld,{ formId, optionId: [optionId] }]);
          }
        }
        return;
      }
      const isOptionSelect = getSelectVal.optionId.findIndex((val) => val === optionId);
      if (isOptionSelect > -1 && getSelectVal.optionId.length > 0) {
        const removeSelectedOption = getSelectVal.optionId.filter((op) => op !== optionId);
        setSelectedOptions([...removeOld, { formId, optionId: [...removeSelectedOption] }]);
        return;
      }
      const val = { formId, optionId: [...getSelectVal.optionId, optionId] };
      setSelectedOptions([...removeOld, val]);
      return;
    }
    const selectedVal = { formId, optionId: [optionId] };
    setSelectedOptions([...selectedOptions, selectedVal]);
  };

  const handleSchoolInfo = ({ target: { name, value } }) => {
    setInputDetails({
      ...inputDetails,
      [name]: value,
    });

    if (name === 'schoolName') {
      setInputErrorState({ ...inputErrorState, schoolNameErrorState: false });
    }
    switch (name) {
      case 'schoolName':
        return setInputErrorState({ ...inputErrorState, schoolNameErrorState: false });
      case 'numberOfStudents':
        return setInputErrorState({ ...inputErrorState, numberOfStudentsErrorState: false });
      case 'schoolState':
        return setInputErrorState({ ...inputErrorState, schoolStateErrorState: false });
      case 'schoolCity':
        return setInputErrorState({ ...inputErrorState, schoolCityErrorState: false });
      case 'name':
        return setInputErrorState({ ...inputErrorState, nameErrorState: false });
      case 'phoneNumber':
        const patternForNumber = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;
        const validPhoneNumber = patternForNumber.test(value);
        return setInputErrorState({ ...inputErrorState, phoneNumberErrorState: false || !validPhoneNumber });
      case 'email':
        const pattern = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/;
        const result = pattern.test(value);
        return setInputErrorState({ ...inputErrorState, emailErrorState: false || !result });
      case 'studentsServed':
        return setInputErrorState({ ...inputErrorState, studentsServedErrorState: false });
      default:
        setInputErrorState({ ...inputErrorState });
        break;
    }
  };

  const handleSchoolStateInfo = (event, selectedOption) => {
    if (!selectedOption) return;
    const { id } = event.target;
    const name = id.split('-')[0];
    const value = selectedOption.id;
    setInputDetails({
      ...inputDetails,
      [name]: value,
    });
    if (name === 'schoolState') {
      setInputErrorState({ ...inputErrorState, schoolStateErrorState: false });
    }
  };

  const handleErrorState = ({ target: { name, value } }) => {
    if (name === 'schoolName') {
      if (value !== '') {
        setInputErrorState({ ...inputErrorState, schoolNameErrorState: false });
      }
      if (value === '') {
        setInputErrorState({ ...inputErrorState, schoolNameErrorState: true });
      }
    }
    if (name === 'numberOfStudents') {
      if (value !== '') {
        setInputErrorState({ ...inputErrorState, numberOfStudentsErrorState: false });
      }
      if (value === '') {
        setInputErrorState({ ...inputErrorState, numberOfStudentsErrorState: true });
      }
    }
    if (name === 'schoolState') {
      if (value !== '') {
        setInputErrorState({ ...inputErrorState, schoolStateErrorState: false });
      }
      if (value === '') {
        setInputErrorState({ ...inputErrorState, schoolStateErrorState: true });
      }
    }
    if (name === 'schoolCity') {
      if (value !== '') {
        setInputErrorState({ ...inputErrorState, schoolCityErrorState: false });
      }
      if (value === '') {
        setInputErrorState({ ...inputErrorState, schoolCityErrorState: true });
      }
    }
    if (name === 'name') {
      if (value !== '') {
        setInputErrorState({ ...inputErrorState, nameErrorState: false });
      }
      if (value === '') {
        setInputErrorState({ ...inputErrorState, nameErrorState: true });
      }
    }
    if (name === 'phoneNumber') {
      if (value !== '') {
        setInputErrorState({ ...inputErrorState, phoneNumberErrorState: false });
      }
      if (value === '') {
        setInputErrorState({ ...inputErrorState, phoneNumberErrorState: false });
      }
    }
    if (name === 'email') {
      if (value !== '') {
        setInputErrorState({ ...inputErrorState, emailErrorState: false });
      }
      if (value === '') {
        setInputErrorState({ ...inputErrorState, emailErrorState: true });
      }
    }
    if (name === 'studentsServed') {
      if (value !== '') {
        setInputErrorState({ ...inputErrorState, studentsServedErrorState: false });
      }
      if (value === '') {
        setInputErrorState({ ...inputErrorState, studentsServedErrorState: true });
      }
    }
  };

  const handleGetBack = () => {
    setActiveIndex(0);
    history.push('/activity-request');
  };

  const handleDeleteChips = (category, data) => () => {
    const activeCat = selected.find((obj) => obj.id === category.id);
    const subCategories = activeCat.subCategories || [];
    const existingIndex = subCategories.findIndex((obj) => obj.id === data.id);
    if (existingIndex !== -1) {
      const clone = [...subCategories];
      clone.splice(existingIndex, 1);
      activeCat.subCategories = clone;
    } else {
      activeCat.subCategories = [...subCategories, data];
    }
    const allSubCategorySelected = selected.flatMap((cat) => cat.subCategories);
    setAllSubCategories([...allSubCategorySelected]);
    const index = selected.findIndex((obj) => obj.id === category.id);
    const cloneSelected = [...selected];
    cloneSelected[index] = activeCat;
    setSelected(cloneSelected);
  };

  const checkIfTextBoxOptionSelected = (formId, options, optionIndex,optionName) => {
    const isSelectedTextBoxIndex = selectedOptions.find((el) => el.formId === formId)?.optionId?.includes(optionIndex);
    if (!isSelectedTextBoxIndex){
      delete otherFields[optionName]
      return false;
    }
    return true;
  };

  const handleTextBoxOptionFields = ({ target: { name, value } }) => {
    setOtherFields({
      ...otherFields,
      [name]: value,
    });
  };

  return (
    <Container className={classes.formWrapper} maxWidth="xl">
      <Grid container={true}>
        <Grid xs={12} sm={12} md={10} lg={9} item={true}>
          <Container maxWidth="md">
            <div onClick={() => history.push('/activity-request')} className={classes.prevContent}>
              &#8249;
              <p>Return to All Categories</p>
            </div>
            <div className={classes.formHeader}>
              <Typography variant="h3">Program Preferences</Typography>
              <Typography variant="body1">
                If you'd like to specify parameters for programs or providers to meet.
              </Typography>
              <Typography variant="body1">please select them below.</Typography>
            </div>
            <div className={classes.schoolInfo}>
              <Typography variant="h6">School Information</Typography>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className={classes.inputLabel}>
                    School Name <span className={classes.required}>*</span>
                  </div>
                  <TextField
                    value={inputDetails.schoolName}
                    onChange={handleSchoolInfo}
                    onBlur={handleErrorState}
                    helperText={inputErrorState.schoolNameErrorState ? 'Please enter the school name' : ''}
                    size="small"
                    fullWidth
                    name="schoolName"
                    variant="outlined"
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} xl={6} lg={6}>
                  <div className={classes.inputLabel}>
                    How many students are at your school ?<span className={classes.required}>*</span>
                  </div>
                  <TextField
                    type="number"
                    value={inputDetails.numberOfStudents}
                    onChange={handleSchoolInfo}
                    onBlur={handleErrorState}
                    helperText={inputErrorState.numberOfStudentsErrorState ? 'Please enter the number of students' : ''}
                    size="small"
                    fullWidth
                    name="numberOfStudents"
                    variant="outlined"
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} xl={6} lg={6}>
                  <div className={classes.inputLabel}>
                    School State <span className={classes.required}>*</span>
                  </div>
                  <Autocomplete
                    className={classes.autoComplete}
                    id="schoolState"
                    name="schoolState"
                    fullWidth
                    options={sortedStates}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name || ''}
                    style={{ width: '100%' }}
                    onChange={handleSchoolStateInfo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="schoolState"
                        onBlur={handleErrorState}
                        helperText={inputErrorState.schoolStateErrorState ? 'Please enter the state' : ''}
                        FormHelperTextProps={{
                          className: classes.helperText,
                        }}
                        label=""
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} xl={6} lg={6}>
                  <div className={classes.inputLabel}>
                    School City <span className={classes.required}>*</span>
                  </div>
                  <TextField
                    value={inputDetails.schoolCity}
                    onChange={handleSchoolInfo}
                    onBlur={handleErrorState}
                    helperText={inputErrorState.schoolCityErrorState ? 'Please enter the school state' : ''}
                    size="small"
                    fullWidth
                    name="schoolCity"
                    variant="outlined"
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className={classes.inputLabel}>
                    How many students do you want to serve with after-school programs?{' '}
                    <span className={classes.required}>*</span>
                  </div>
                  <TextField
                    type="number"
                    value={inputDetails.studentsServed}
                    onChange={handleSchoolInfo}
                    onBlur={handleErrorState}
                    helperText={inputErrorState.studentsServedErrorState ? 'Please enter this field' : ''}
                    size="small"
                    fullWidth
                    name="studentsServed"
                    variant="outlined"
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.contactInfo}>
                          <Typography variant="h6">Contact Information</Typography>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                                  
                  <div>
                    Your Name <span className={classes.required}>*</span>
                  </div>
                  <TextField
                    value={inputDetails.name}
                    onChange={handleSchoolInfo}
                    onBlur={handleErrorState}
                    helperText={inputErrorState.nameErrorState ? 'Please enter the name' : ''}
                    size="small"
                    fullWidth
                    name="name"
                    variant="outlined"
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                                  
                  <div>Phone Number</div>
                  <TextField
                    value={inputDetails.phoneNumber}
                    onChange={handleSchoolInfo}
                    onBlur={handleErrorState}
                    helperText={inputErrorState.phoneNumberErrorState ? 'Please enter correct phone number' : ''}
                    size="small"
                    fullWidth
                    name="phoneNumber"
                    variant="outlined"
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                                  
                  <div>
                    Email Address <span className={classes.required}>*</span>
                  </div>
                  <TextField
                    value={inputDetails.email}
                    onChange={handleSchoolInfo}
                    onBlur={handleErrorState}
                    helperText={inputErrorState.emailErrorState ? 'Please enter correct email' : ''}
                    size="small"
                    fullWidth
                    name="email"
                    variant="outlined"
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.programTime}>
                          
              <Typography variant="h6">
                When do you plan to implement these programs ? <span className={classes.required}>*</span>
              </Typography>
              <RadioGroup
                className={classes.radioBtn}
                row={true}
                name="program-intervals"
                value={inputDetails.radioButtonValue}
                onChange={(e) => setInputDetails({ ...inputDetails, radioButtonValue: e.target.value })}
              >
                <FormControlLabel value="ASAP" control={<Radio />} label="ASAP" />
                <FormControlLabel value="Summer" control={<Radio />} label="Summer" />
                <FormControlLabel value="Next Semester" control={<Radio />} label="Next Semester" />
                <FormControlLabel value="Next School Year" control={<Radio />} label="Next School Year" />
              </RadioGroup>
            </div>
            <div className={classes.demographics}>
              <Typography variant="h6">Demographics</Typography>{' '}
              {formData.slice(0, 2).map((options, formId) => {
                return (
                  <div key={formId}>
                    <Typography variant="body1">
                      {options.title} {options.required && <span className={classes.required}>*</span>}{' '}
                    </Typography>
                    <ul className={classes.list}>
                      {options.options.map((option, optionId) => (
                        <li
                          className={cs({ [classes.selectedEl]: checkIfOptionIsSelected(options.id, optionId) })}
                          onClick={handleFormSelection(options.id, optionId)}
                          key={optionId}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
            <Divider />
            <div className={classes.preference}>
              <Typography variant="h6"> Additional Preference</Typography>
              {formData.slice(2, formData.length).map((options, formId) => {
                return (
                  <div key={formId}>
                    <Typography variant="body1">
                      {options.title} {options.required && <span className={classes.required}>*</span>}{' '}
                    </Typography>
                    {options?.caption && <Typography variant="caption">{options?.caption}</Typography>}
                    <ul className={classes.list}>
                      {options.options.map((option, optionId) => (
                        <li
                          className={cs({ [classes.selectedEl]: checkIfOptionIsSelected(options.id, optionId) })}
                          onClick={handleFormSelection(options.id, optionId)}
                          key={optionId}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                    {options?.textBoxOptions?.map((textBoxOption, index) => {
                      return (
                        checkIfTextBoxOptionSelected(options.id, options, textBoxOption.optionIndex,textBoxOption.optionText) && (
                          <div key={index} className={classes.otherTextBox}>
                            <Typography variant="body1">
                              {' '}
                              {textBoxOption?.optionText} <span className={classes.required}>*</span>{' '}
                            </Typography>
                            <TextField
                              onChange={handleTextBoxOptionFields}
                              value={otherFields[textBoxOption?.optionText]}
                              id="outlined-multiline-static"
                              multiline
                              rows={4}
                              name={textBoxOption?.optionText}
                              variant="outlined"
                            />
                          </div>
                        )
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <Divider />
            <div className={classes.bottomField}>
              <Typography variant="body1"> Additional Comments and/or Request </Typography>
              <TextField
                className={classes.textArea}
                onChange={handleSchoolInfo}
                value={inputDetails.additionalComment}
                id="outlined-multiline-static"
                multiline
                rows={4}
                name="additionalComment"
                variant="outlined"
              />
              <FormControlLabel
                name="permission"
                classes={{ root: classes.icon }}
                onChange={() => setInputDetails({ ...inputDetails, permission: !inputDetails.permission })}
                value={inputDetails.permission}
                control={<Checkbox color="primary" />}
                label={
                  <p>
                    I want AfterSchool HQ to search for programs on my organization’s behalf{' '}
                    <span className={classes.required}>*</span>{' '}
                  </p>
                }
                labelPlacement="end"
              />
            </div>
          </Container>
        </Grid>
        <Grid className={classes.sideContent} xs={12} sm={12} lg={3} md={12} item={true}>
          <Container>
            <Grid className={classes.innerContent} container={true}>
              <Grid className={classes.sideContentHeader} item={true}>
                <br />
                <Typography className={classes.sideTitle} variant="h6">
                  Here's what's on your wishlist.
                </Typography>
                <div className={classes.chipsWrapper}>
                  <div className={classes.chips}>
                    {selected.map((cat) => {
                      return cat.subCategories.map((data, i) => {
                        return (
                          <Button key={i} variant="contained">
                            <div onClick={handleDeleteChips(cat, data)} className={classes.chipContent}>
                              <div>{data.category}</div>
                              <div className="close">⨯</div>
                            </div>
                          </Button>
                        );
                      });
                    })}
                  </div>
                </div>
                <Button className="add-more" onClick={handleGetBack} variant="outlined">
                  + Add More Programs
                </Button>
              </Grid>
              <Grid className={classes.submitBtn} item={true}>
                <Divider light={true} />
                <Typography variant="body1">
                  When you press Submit, our specialized team will begin curating a list of providers that we think will
                  be a perfect match for your school.
                </Typography>
                <Button disabled={disableSubmit} onClick={handleSubmit} variant="contained">
                  Submit
                </Button>
                <Snackbar open={open} color="error" autoHideDuration={3000} onClose={handleClose}>
                  <Alert color="error" severity="error">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProgramForm;
