import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  cards: {
    marginBottom: '48px',
  },
  activities: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '15px',
    },
    '& h5': {
      marginBottom: '40px',
      fontSize: '15px',
    },
    '& h6': {
      fontSize: '15px',
      marginBottom: '10px',
    },
    '& p': {
      fontSize: '14px',
    },
    '& button': {
      height: '35px',
      width: '198px',
      fontWeight: '600',
      color: '#fff',
    },
    "& a":{
      textDecoration: 'none',
      color: '#fff'
    }
  },
  whatsNext: {
    '& .MuiTypography-body1': {
      fontFamily: 'Montserrat-italic',
    },
    marginBottom: '40px',
    width: '576px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '-webkit-fill-available',
    },
  },
  card: {
    justifyContent: 'center',
    textAlign: 'center',
    '& img': {
      height: '75px',
      marginBottom: '10px',
    },
  },
  thankYou: {
    paddingTop: '30px',
    paddingBottom: '25px',
    backgroundColor: '#cddbcf',
    marginBottom: '35px',
    marginTop: '-9px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
    },
    '& h4': {
      fontFamily: 'Montserrat-bold',
      fontSize: '24px',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: '19px',
      },
    },
  },
  cardHeader: {
    margin: '30px',
  },
  lineBreak: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  mainImage: {
    '& img': {
      height: '40px',
      [theme.breakpoints.down('xs')]: {
        height: '20px',
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
    },
    '& h4': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        marginBottom: '-10px',
      },
    },
    '& span': {
      fontSize: '11px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '7px',
      },
    },
  },
}));

export default useStyles;
