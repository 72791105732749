import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  categoriesContainer: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% + 100px)',
    },
  },
  categoriesSection: {
    marginBottom: '0px',
  },
  activityWrapper: {
    fontFamily: 'Montserrat',
    paddingLeft: '0px',
    paddingRight: '0px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  counter: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      top: '5px',
      right: '15px',
      position: 'absolute',
      backgroundColor: '#00C5A3',
      color: '#fff',
      fontSize: '16px',
      fontWeight: '600',
      width: '30px',
      height: '30px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '1px 1px 15px 4px rgb(74 74 74 / 30%)',
    },
  },
  sideContainer: {
    paddingLeft: '0px',
    paddingRight: '0px',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  sideContantHeder: {
    [theme.breakpoints.up('sm')]: {
      display: 'none !important',
    },
  },
  prevContent: {
    color: '#b2b2b4',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginLeft: '3px',
      fontSize: '12px',
      fontWeight: '300',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#b2b2b4',
    },
  },

  mainWrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  selectedText: {
    marginLeft: '3px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  checkBox2: {
    '& svg': {
      '& path': {
        fill: '#fff',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '15px',
      height: '15px',
    },
    '& .MuiSvgIcon-root': {
      [theme.breakpoints.down('sm')]: {
        width: '15px',
        height: '15px',
      },
    },
    backgroundColor: '#fff',
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: '50%',
    cursor: 'pointer',
    height: '23px',
    left: '0',
    top: '0',
    width: '23px',

    '&:after': {
      border: '2px solid #fff',
      borderTop: 'none',
      borderRight: 'none',
      content: '" "',
      height: '6px',
      left: '7px',
      opacity: '0',
      top: '8px',
      transform: 'rotate(-45deg)',
      width: '12px',
    },

    '& path': {
      color: '#fff',
    },
  },
  checkBox: {
    [theme.breakpoints.down('sm')]: {
      width: '15px',
      height: '15px',
    },
    '& svg': {
      '& path': {
        fill: '#fff',
      },
      [theme.breakpoints.down('sm')]: {
        width: '15px',
        height: '15px',
      },
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '50%',
    },
    backgroundColor: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: '50%',
    cursor: 'pointer',
    height: '23px',
    left: '0',
    top: '0',
    width: '23px',

    '&:after': {
      border: '2px solid #fff',
      borderTop: 'none',
      borderRight: 'none',
      content: '" "',
      height: '6px',
      left: '7px',
      opacity: '0',
      top: '8px',
      transform: 'rotate(-45deg)',
      width: '12px',
    },

    '& path': {
      color: '#fff',
    },
  },
  chipContent: {
    display: 'flex',
    gap: '5px',
  },
  chipsWrapper: {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar ': {
      width: '0px',
      background: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 94px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 61px)',
    },
  },
  chips: {
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0px 12px',
    marginTop: '10px',
    '& button': {
      backgroundColor: theme.palette.primary.dark,
      paddingRight: '8px',
      fontWeight: '600',
      color: 'white',
      margin: '5px',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        '& .close': {
          visibility: 'visible',
        },
      },
    },
    '& .close': {
      visibility: 'hidden',
      [theme.breakpoints.down('sm')]: {
        visibility: 'visible',
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.dark,

    padding: '0px 15px',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px',
    },
    '& .MuiTypography-button': {
      fontWeight: '600',
    },
  },
  imgWraper: {
    position: 'relative',
    border: '3px solid #fff',
  },
  imgBorder: {
    borderRadius: '8px',
    border: `3px solid ${theme.palette.primary.dark}`,
  },
  overlay: {
    borderRadius: '5px',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,0.7)',
  },
  active: {
    [theme.breakpoints.down('sm')]: {
      top: '-6px',
      left: '6px',
    },
    zIndex: '1',
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    left: '10px',
    top: '-10px',
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.dark,
    },
  },
  active2: {
    [theme.breakpoints.down('sm')]: {
      top: '-6px',
      left: '6px',
    },

    zIndex: '1',
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    left: '8px',
    top: '-11px',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'flex-end',
    '-webkit-align-items': 'flex-end',
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.dark,
    },
  },
  cardContent: {
    position: 'relative',

    '& .view-sub': {
      position: 'absolute',
      bottom: '0',
      left: 'calc(50% - 59px)',
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        width: '117px',
        textAlign: 'center',
      },
    },
  },
  mainContentWrapper: {
    '& .MuiGrid-spacing-xs-3': {
      margin: '10px !important',
    },
  },
  cardHeader: {
    fontFamily: 'Montserrat',
    margin: '30px',
    maxWidth: '606px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      padding: '0px 17px',
      margin: '30px 0px',
      '& h4': {
        fontSize: '17px',
        fontFamily: 'Montserrat',
      },
      '& .MuiTypography-caption': {
        fontFamily: 'Montserrat',
      },
      '& span': {
        fontFamily: 'Montserrat',
        lineHeight: '1',
        fontWeight: '600',
        color: 'rgb(0,0,0,0.3)',
      },
    },
    '& .MuiTypography-h4': {
      fontFamily: 'Montserrat',
      fontSize: '25px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#282828',
      marginBottom: '15px',
    },

    '& .MuiTypography-caption': {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#494949',
    },
  },
  cardActionArea: {
    '& .MuiCardContent-root': {
      padding: '0px',
    },
  },
  overlayTitle: {
    top: '50%',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: 'fit-content',
    fontSize: '20px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      top: '50%',
      fontSize: '13px',
      width: 'auto',
    },
  },
  cards: {
    background: 'rgba(0,0,0,0.7)',
    maxHeight: '205px',

    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      height: '109px',
    },
    [theme.breakpoints.up('md')]: {
      height: '205px',
    },
  },

  '@keyframes slideInFromTop': {
    '0%': {
      transform: 'translateY(100%)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  '@keyframes slideInFromBottom': {
    '0%': {
      transform: 'translateY(100%)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  mobileBottomContent: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 157px) !important',
    },
  },
  sideContent: {
    backgroundColor: '#cddbcf',
    display: 'flex',
    height: 'calc(100vh - 64px)',
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: '64px',
      backgroundColor: '#cddbcf',
    },
    [theme.breakpoints.down('sm')]: {
      zIndex: '10',
      position: 'fixed',
      bottom: '0',
      height: '75px',
    },
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  preferenceBtn: {
    textAlign: 'center',
    marginBottom: '15px',
    padding: '0px 12px',
    '& .MuiButton-label': {
      fontFamily: 'Montserrat-semi-bold',
    },
    '& button': {
      width: '100%',
      backgroundColor: '#fff',
      color: theme.palette.primary.dark,
      lineHeight: '33px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
  },
  sideContentHeaderShow: {
    [theme.breakpoints.down('sm')]: {
      display: 'block !important',
    },
  },
  sideContentHeader: {
    height: 'calc(100% - 94px)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& h6': {
      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
      },
    },
  },
  sideTitle: {
    textAlign: 'center',
    padding: '0px 12px',
    fontFamily: 'Montserrat',
  },

  //for card 2
  imgBorder2: {
    borderRadius: '7px',
    border: `3px solid ${theme.palette.primary.dark}`,
    [theme.breakpoints.down('sm')]: {
      borderRadius: '8px',
    },
  },
  isChecked2: {
    backgroundColor: `${theme.palette.primary.dark} !important`,
    '& svg': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '50%',
      '& path': {
        fill: '#fff',
      },
    },
  },
  cardContent2: {
    position: 'relative',
  },
}));

export default useStyles;
