import React from 'react';
import Header from './Header';

const ActivityRequestPage = ({ Component }) => {
  return (
    <>
      <Header />
      <Component />
    </>
  );
};

export default ActivityRequestPage;
