import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import cs from 'classnames';
import { useParams } from 'react-router-dom';

import useSignUpStore from 'store/signupStore';
import Alert from 'components/Alert';

import useStyles from './styles';

const SignUpForm = ({ updateStep }) => {
  const classes = useStyles();
  const { inviteCode } = useParams();
  const { doRegistration, userDetails, error, resetState, id, doFetchUserSignUpDetails } = useSignUpStore();
  const [confirmPasswordErrorState, setConfirmPasswordErrorState] = useState();
  const [confirmPasswordState, setConfirmPasswordState] = useState(false);
  const [emailErrorState, setEmailErrorState] = useState(false);
  const [phoneErrorState, setPhoneErrorState] = useState(false);
  const [PasswordErrorState, setPasswordErrorState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableEntry, setDisableEntry] = useState(false);
  const [signUpDetails, setSignUpDetails] = useState({
    ...userDetails,
    password: '',
    confirmPassword: '',
  });

  const handleTextChange = (e) => {
    const {
      target: { name, value },
    } = e;
    if (name === 'phone') {
      if (!value) {
        setPhoneErrorState(false);
        setDisableEntry(false);
      } else {
        if (value.length > 12) {
          return e.preventDefault();
        }
        const pattern = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;
        const result = pattern.test(value);
        setPhoneErrorState(!result);
        setDisableEntry(!result);
      }
    }

    setSignUpDetails({
      ...signUpDetails,
      [name]: value,
    });
  };

  const handleErrorState = ({ target: { name, value } }) => {
    if (name === 'email') {
      const pattern = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/;
      const result = pattern.test(value);
      setEmailErrorState(!result);
      setDisableEntry(!result);
    }

    if (name === 'password') {
      if (value !== '') {
        setPasswordErrorState(false);
      }
      if (value === '') {
        setDisableEntry(true);
        setPasswordErrorState(true);
      }
    }
    if (name === 'confirmPassword') {
      if (value !== '') {
        setConfirmPasswordState(false);
      }
      if (value === '') {
        setDisableEntry(true);
        setConfirmPasswordState(true);
      }
      if (value !== signUpDetails.password) {
        setConfirmPasswordErrorState(true);
      }
      if (value === signUpDetails.password) {
        setConfirmPasswordErrorState(false);
      }
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (confirmPasswordErrorState) return;
    try {
      setLoading(true);
      const { confirmPassword, ...rest } = signUpDetails;
      const params = {
        ...rest,
        registrantInviteCodeId: inviteCode ? +inviteCode : id,
      };
      const resp = await doRegistration(params);
      if (resp) {
        resetState();
        updateStep();
      }
      setLoading(false);
    } catch ({ response }) {
      setLoading(false);
    }
  };

  const getUserSignUpDetails = async () => {
    const code = inviteCode ? +inviteCode : id;
    const resp = await doFetchUserSignUpDetails(code);
    if (!resp) return;
    const { email } = resp;
    setSignUpDetails({ ...signUpDetails, email });
  };

  useEffect(() => {
    getUserSignUpDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSignUp}>
      <div className={classes.signUpForm}>
        <div className={classes.textFieldWrapper}>
          <div className={classes.label}>Your Email Address *</div>
          <TextField
            fullWidth
            name="email"
            value={signUpDetails.email}
            variant="outlined"
            placeholder="sample@email.com"
            onChange={handleTextChange}
            helperText={emailErrorState ? 'Please enter correct email' : ''}
            onBlur={handleErrorState}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            classes={{
              root: classes.textField,
            }}
            inputProps={{
              required: 'required',
              'data-testid': 'signup-email',
            }}
          />
        </div>
        <div className={classes.textFieldWrapper}>
          <div className={classes.label}>Phone Number</div>
          <TextField
            fullWidth
            name="phone"
            value={signUpDetails.phone}
            variant="outlined"
            placeholder="000-000-0000"
            onChange={handleTextChange}
            helperText={phoneErrorState ? 'Please enter correct phone number' : ''}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            classes={{
              root: classes.textField,
            }}
            inputProps={{
              'data-testid': 'contact-number',
            }}
          />
        </div>
        <div className={classes.textFieldWrapper}>
          <div className={classes.label}>Password *</div>
          <TextField
            fullWidth
            name="password"
            type="password"
            value={signUpDetails.password}
            variant="outlined"
            placeholder="****************"
            onBlur={handleErrorState}
            onChange={handleTextChange}
            helperText={PasswordErrorState ? 'Please enter correct password' : ''}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            classes={{
              root: classes.textField,
            }}
            inputProps={{
              required: 'required',
              'data-testid': 'password',
            }}
          />
        </div>
        <div className={classes.textFieldWrapper}>
          <div className={classes.label}>Confirm Password *</div>
          <TextField
            fullWidth
            name="confirmPassword"
            value={signUpDetails.confirmPassword}
            variant="outlined"
            type="password"
            placeholder="****************"
            onChange={handleTextChange}
            classes={{
              root: classes.textField,
            }}
            inputProps={{
              required: 'required',
              'data-testid': 'confirm-password',
            }}
            onBlur={handleErrorState}
            helperText={
              confirmPasswordErrorState
                ? 'Confirm Password did not matched'
                : `${confirmPasswordState ? 'Please enter the password' : ''}`
            }
            FormHelperTextProps={{
              className: classes.helperText,
            }}
          />
        </div>
        {error && <Alert severity="error" message={error} />}
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={cs(classes.nextButton, { [classes.isError]: error })}
          disabled={loading || disableEntry}
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default SignUpForm;
