import GovernmentCommunity from './Assests/GovernmentCommunity/any-government-community.jpg';
import Music from './Assests/Music/any-music-program.jpg';
import PerformingArts from './Assests/PerformingArts/any-performing-arts.jpg';
import ReligiousSpiritual from './Assests/ReligiousSpiritual/any-religious.jpg';
import SportsRecreation from './Assests/SportsRecreation/any-sports-recreation.jpg';
import TechnologyMedia from './Assests/TechnologyMedia/any-technology-media.jpg';
import ScienceEngineering from './Assests/ScienceEngineering/any-science-and-engineering.jpg';
import BusinessFinance from './Assests/BusinessFinance/any-business-finance.jpg';
import Arts from './Assests/Arts/any-arts-crafts.jpg';
import Stem from './Assests/STEM /any-stem.jpg';
import CulturalAdvocate from './Assests/CulturalAdvocate/any-culture-advocacy.jpg';
import Academic from './Assests/Academic/any-academic-program.jpg';
import LanguageArts from './Assests/LanguageArts/any-language-arts.jpg';
import SocialEmotionalLearning from './Assests/SocialEmotionalLearning/any-social-emotional-learning.jpg';
// sub catagories
//Government and Community
import juniorRotc from './Assests/GovernmentCommunity/junior-rotc.jpg';
import Law from './Assests/GovernmentCommunity/law.jpg';
import Politics from './Assests/GovernmentCommunity/politics.jpg';
import StudentCouncil from './Assests/GovernmentCommunity/student-council.jpg';
import Volunteering from './Assests/GovernmentCommunity/volunteering.jpg';
//Music
import Band from './Assests/Music/band.jpg'; //used in PerformingArts // used in Arts
import Choir from './Assests/Music/choir.jpg'; //used in PerformingArts // used in Arts
import JazzBand from './Assests/Music/jazz-band.jpg'; //used in PerformingArts //used in Arts
import MusicProduction from './Assests/Music/music-production.jpg'; //used in Arts
import Orchestra from './Assests/Music/orchestra.jpg'; //used in PerformingArts //used in Arts
import SongWriting from './Assests/Music/song-writing.jpg'; //used in Language Arts
//PerformingArts
import Acting from './Assests/PerformingArts/acting.jpg'; //used in Arts
import Comedy from './Assests/PerformingArts/comedy.jpg'; //used in Arts
import Dance from './Assests/PerformingArts/dance.jpg'; //used in Arts
import HipHopRap from './Assests/PerformingArts/hip-hop-rap.jpg'; //used in Arts
import SpokenWord from './Assests/PerformingArts/spoken-word.jpg'; //used in Arts
import Theatre from './Assests/PerformingArts/theatre.jpg'; //used in Arts
//ReligiousSpiritual
import Astrology from './Assests/ReligiousSpiritual/astrology.jpg';
import Atheism from './Assests/ReligiousSpiritual/atheism.jpg';
import Buddhism from './Assests/ReligiousSpiritual/buddhism.jpg';
import Christianity from './Assests/ReligiousSpiritual/christianity.jpg';
import Ethics from './Assests/ReligiousSpiritual/ethics.jpg'; //used in Academic
import Hinduism from './Assests/ReligiousSpiritual/hinduism.jpg';
import Islam from './Assests/ReligiousSpiritual/islam.jpg';
import Judaism from './Assests/ReligiousSpiritual/judaism.jpg';
import Philosophy from './Assests/ReligiousSpiritual/philosophy.jpg'; //used in Academic
//SportsRecreation
import Baseball from './Assests/SportsRecreation/baseball.jpg';
import Basketball from './Assests/SportsRecreation/basketball.jpg';
import Bowling from './Assests/SportsRecreation/bowling.jpg';
import Boxing from './Assests/SportsRecreation/boxing.jpg';
import Cheerleading from './Assests/SportsRecreation/cheerleading.jpg';
import CrossCountry from './Assests/SportsRecreation/cross-country.jpg';
import Crossfit from './Assests/SportsRecreation/crossfit.jpg';
import DanceTeam from './Assests/SportsRecreation/dance-team.jpg';
import Diving from './Assests/SportsRecreation/diving.jpg';
import Football from './Assests/SportsRecreation/football.jpg';
import Golf from './Assests/SportsRecreation/golf.jpg';
import Gymnastics from './Assests/SportsRecreation/gymnastics.jpg';
import Lacrosse from './Assests/SportsRecreation/lacrosse.jpg';
import MartialArts from './Assests/SportsRecreation/martial-arts.jpg';
import Skateboarding from './Assests/SportsRecreation/skateboarding.jpg';
import Soccer from './Assests/SportsRecreation/soccer.jpg';
import Swimming from './Assests/SportsRecreation/swimming.jpg';
import TableTennis from './Assests/SportsRecreation/table-tennis.jpg';
import TrackAndField from './Assests/SportsRecreation/track-and-field.jpg';
import Volleyball from './Assests/SportsRecreation/volleyball.jpg';
import Weightlifting from './Assests/SportsRecreation/weightlifting.jpg';
import Wrestling from './Assests/SportsRecreation/wrestling.jpg';
//ScienceEngineering
import Architecture from './Assests/ScienceEngineering/architecture.jpg';
import Astronomy from './Assests/ScienceEngineering/astronomy.jpg';
import Astrophysics from './Assests/ScienceEngineering/astrophysics.jpg';
import Biology from './Assests/ScienceEngineering/biology.jpg';
import Chemistry from './Assests/ScienceEngineering/chemistry.jpg';
import Engineering from './Assests/ScienceEngineering/engineering.jpg';
import Mathmatics from './Assests/ScienceEngineering/mathmatics.jpg';
import Physics from './Assests/ScienceEngineering/physics.jpg';
import RoboticsHardwareEngineering from './Assests/ScienceEngineering/robotics-hardware-engineering.jpg';
//CulturalAdvocate
import AfricanCultures from './Assests/CulturalAdvocate/african-cultures.jpg';
import AsianCultures from './Assests/CulturalAdvocate/asian-cultures.jpg';
import BlackAmericanCultures from './Assests/CulturalAdvocate/black-american-cultures.jpg';
import DiversityAndInclusion from './Assests/CulturalAdvocate/diversity-and-inclusion.jpg';
import EuropeanCulture from './Assests/CulturalAdvocate/european-culture.jpg';
import LatinxCultures from './Assests/CulturalAdvocate/latinx-cultures.jpg';
import Lgbtq from './Assests/CulturalAdvocate/lgbtq.jpg';
import RacialEquity from './Assests/CulturalAdvocate/racial-equity.jpg';
//BusinessFinance
import Accounting from './Assests/BusinessFinance/accounting.jpg';
import Business from './Assests/BusinessFinance/business.jpg';
import Economics from './Assests/BusinessFinance/economics.jpg';
import Entrepreneurship from './Assests/BusinessFinance/entrepreneurship.jpg';
import FinancialLiteracy from './Assests/BusinessFinance/financial-literacy.jpg';
import Leadership from './Assests/BusinessFinance/leadership.jpg'; //used in Careers and Character
import Management from './Assests/BusinessFinance/management.jpg';
//Arts
import AnyArtsCrafts from './Assests/Arts/any-arts-crafts.jpg';
import Ceramics from './Assests/Arts/ceramics.jpg';
import CreativeWriting from './Assests/Arts/creative-writing.jpg'; //used in Language Arts
import DigitalArtsGraphicDesign from './Assests/Arts/digital-arts-graphic-design.jpg';
import Fashion from './Assests/Arts/fashion.jpg';
import FilmVideoProduction from './Assests/Arts/film-video-production.jpg';
import JewelryMaking from './Assests/Arts/jewelry-making.jpg';
import Painting from './Assests/Arts/painting.jpg';
import photography from './Assests/Arts/photography.jpg';
import Poetry from './Assests/Arts/poetry.jpg'; //used in Language Arts
import ScriptWriting from './Assests/Arts/script-writing.jpg'; //used in Language Arts
import Sculpting from './Assests/Arts/sculpting.jpg';
import SewingKnirtting from './Assests/Arts/sewing-knirtting.jpg';
import WoodWorking from './Assests/Arts/woodworking.jpg';
//Academic
import Debate from './Assests/Academic/debate.jpg';
import NationalHonorSociety from './Assests/Academic/national-honor-society.jpg';
import Pentathalon from './Assests/Academic/pentathalon.jpg';
import Speech from './Assests/Academic/speech.jpg';
import SpellQuizBowl from './Assests/Academic/spell-quiz-bowl.jpg';
import Tutoring from './Assests/Academic/tutoring.jpg';
//LanguageArts
import Journalism from './Assests/LanguageArts/journalism.jpg';
import Reading from './Assests/LanguageArts/reading.jpg';
//Career And Character
import AnyCareerAndCharacter from './Assests/CareerAndCharacter/any-career-and-character.jpg';
import Collaboration from './Assests/CareerAndCharacter/collaboration.jpg';
import Communication from './Assests/CareerAndCharacter/communication.jpg';
import Creativity from './Assests/CareerAndCharacter/creativity.jpg';
import CriticalThinking from './Assests/CareerAndCharacter/critical-thinking.jpg';
import FlexibilityAndResiliance from './Assests/CareerAndCharacter/flexibility-and-resiliance.jpg';
import Initiative from './Assests/CareerAndCharacter/initiative.jpg';
import Productivity from './Assests/CareerAndCharacter/productivity.jpg';
import SocialSkills from './Assests/CareerAndCharacter/social-skills.jpg';
//Technology & Media
import SocialMedia from './Assests/TechnologyMedia/social-media.jpg';
import UXUIDesign from './Assests/TechnologyMedia/ui-ux-design.jpg';
import WebsiteDesignDevelopment from './Assests/TechnologyMedia/website-design-development.jpg'
// Arts & Crafts
import Blacksmithing from './Assests/ArtsCrafts/blacksmithing.jpg'
import Photography from './Assests/Arts/photography.jpg'

export const categories = [
  {
    id: 'recxmU223fDuymyU2',
    category: 'Government & Community Programs',
    url: GovernmentCommunity,
    subCategories: [
      {
        id: 'recxmU223fDuymyU2',
        category: 'Any Government & Community Program',
        url: GovernmentCommunity,
      },
      {
        id: 'recQBpysQEQMDkBv8',
        category: 'Junior ROTC',
        url: juniorRotc,
      },
      {
        id: 'recCboXghREBXdXe7',
        category: 'Law',
        url: Law,
      },
      {
        id: 'reccwmoZwPqYxmEcp',
        category: 'Politics',
        url: Politics,
      },
      {
        id: 'recjLTt5AlFLEvE3d',
        category: 'Student Council',
        url: StudentCouncil,
      },
      {
        id: 'recNX60VCAaU7sICM',
        category: 'Volunteering',
        url: Volunteering,
      },
    ],
  },
  {
    id: 'recXCFyXTMgRIWjAj',
    category: 'Music',
    url: Music,
    subCategories: [
      {
        id: 'recXCFyXTMgRIWjAj',
        category: 'Any Music Program',
        url: Music,
      },
      {
        id: 'reccYp3Ss4jmhkUPC',
        category: 'Band',
        url: Band,
      },
      {
        id: 'recTUN1FHyNbsqaCU',
        category: 'Choir',
        url: Choir,
      },
      {
        id: 'rectaXCWD6trG7BmY',
        category: 'Jazz Band',
        url: JazzBand,
      },
      {
        id: 'reci4anB0OYb7nFPU',
        category: 'Music Production',
        url: MusicProduction,
      },
      {
        id: 'rec02K4HK6yI7VeX0',
        category: 'Orchestra',
        url: Orchestra,
      },
      {
        id: 'recP05jQIApez2hwB',
        category: 'Song Writing',
        url: SongWriting,
      },
    ],
  },
  {
    id: 'recKTuRfFxcENfj30',
    category: 'Performing Arts',
    url: PerformingArts,
    subCategories: [
      {
        id: 'recKTuRfFxcENfj30',
        category: 'Any Performing Arts Program',
        url: PerformingArts,
      },
      {
        id: 'reccYp3Ss4jmhkUPC',
        category: 'Band',
        url: Band,
      },
      {
        id: 'recTUN1FHyNbsqaCU',
        category: 'Choir',
        url: Choir,
      },
      {
        id: 'rectaXCWD6trG7BmY',
        category: 'Jazz Band',
        url: JazzBand,
      },
      {
        id: 'recpM19X5fVFwswAv',
        category: 'Acting',
        url: Acting,
      },
      {
        id: 'rec02K4HK6yI7VeX0',
        category: 'Orchestra',
        url: Orchestra,
      },
      {
        id: 'rec7yfsQ4stFkoOzj',
        category: 'Comedy',
        url: Comedy,
      },
      {
        id: 'recQsOZWB31OA3Ibm',
        category: 'Dance',
        url: Dance,
      },
      {
        id: 'reci66852xkOMGcq4',
        category: 'Hip Hop / Rap',
        url: HipHopRap,
      },
      {
        id: 'reclcSZIHnGGtghoi',
        category: 'Spoken Word',
        url: SpokenWord,
      },
      {
        id: 'recoNX3PFXaBDc6YF',
        category: 'Theater',
        url: Theatre,
      },
    ],
  },
  {
    id: 'recpLoX5FqH4PCmlY',
    category: 'Religious & Spiritual Programs',
    url: ReligiousSpiritual,
    subCategories: [
      {
        id: 'recpLoX5FqH4PCmlY',
        category: 'Any Religious And Spiritual Program',
        url: ReligiousSpiritual,
      },
      {
        id: 'recaXMELap4uYsNet',
        category: 'Astrology',
        url: Astrology,
      },
      {
        id: 'recwYlvTwHtK77D58',
        category: 'Atheism',
        url: Atheism,
      },
      {
        id: 'recPsoEHzldhXeiDW',
        category: 'Buddhism',
        url: Buddhism,
      },
      {
        id: 'rec5dGVETNeRPa3zS',
        category: 'Christianity',
        url: Christianity,
      },
      {
        id: 'recS8M9ZC1K7QODoz',
        category: 'Ethics',
        url: Ethics,
      },
      {
        id: 'rectG18DJvFRaPT2k',
        category: 'Hinduism',
        url: Hinduism,
      },
      {
        id: 'recvOay1W2C43f9dU',
        category: 'Islam',
        url: Islam,
      },
      {
        id: 'rec5ebbD1ywPt6IaQ',
        category: 'Judaism',
        url: Judaism,
      },
      {
        id: 'recOvswi5H4tF4zak',
        category: 'Philosophy',
        url: Philosophy,
      },
    ],
  },
  {
    id: 'recl108dOlGi8XnLt',
    category: 'Sports & Recreation Programs',
    url: SportsRecreation,
    subCategories: [
      {
        id: 'recl108dOlGi8XnLt',
        category: 'Any Sports & Recreation Program',
        url: SportsRecreation,
      },
      {
        id: 'recG3Tq3IHbQlNUOu',
        category: 'Baseball',
        url: Baseball,
      },
      {
        id: 'recDge66mRhOY3FoX',
        category: 'Basketball',
        url: Basketball,
      },
      {
        id: 'rec2hZ0EPx9jdWVbG',
        category: 'Bowling',
        url: Bowling,
      },
      {
        id: 'recKzOe4Kp3uZ3sfd',
        category: 'Boxing',
        url: Boxing,
      },
      {
        id: 'recCPL1D5xAlLRWXm',
        category: 'Cheerleading',
        url: Cheerleading,
      },
      {
        id: 'reclmGg257VqqoyrH',
        category: 'Cross Country',
        url: CrossCountry,
      },
      {
        id: 'recLE3QTANZkALuCR',
        category: 'Crossfit',
        url: Crossfit,
      },
      {
        id: 'recvQKVmbbw58wWnf',
        category: 'Dance Team',
        url: DanceTeam,
      },
      {
        id: 'recq6MLyx9F8nmMsn',
        category: 'Diving',
        url: Diving,
      },
      {
        id: 'rec4SYYF16Kokjjgs',
        category: 'Football',
        url: Football,
      },
      {
        id: 'recquXP0HnvXDDoN2',
        category: 'Golf',
        url: Golf,
      },
      {
        id: 'recff8BdidKuyZId8',
        category: 'Gymnastics',
        url: Gymnastics,
      },
      {
        id: 'recG9wIDsCLiKdwr6',
        category: 'Lacrosse',
        url: Lacrosse,
      },
      {
        id: 'recsYaTtmUSvImtY7',
        category: 'Martial Arts',
        url: MartialArts,
      },
      {
        id: 'recuK612HRIajPdTP',
        category: 'Skateboarding',
        url: Skateboarding,
      },
      {
        id: 'recCr7Kcty5wgEH4P',
        category: 'Soccer',
        url: Soccer,
      },
      {
        id: 'reccMAmkeCpED83vb',
        category: 'Swimming',
        url: Swimming,
      },
      {
        id: 'rec1fgIF1bCfQp48B',
        category: 'Table Tennis',
        url: TableTennis,
      },
      {
        id: 'rec4TVJIX74Jh2vlQ',
        category: 'Track & Field',
        url: TrackAndField,
      },
      {
        id: 'rec0AX5jOEGdT0vz3',
        category: 'Volleyball',
        url: Volleyball,
      },
      {
        id: 'recZb81Yubh35Tmy7',
        category: 'Weightlifting',
        url: Weightlifting,
      },
      {
        id: 'recPMD6W0LnxYtOv4',
        category: 'Wrestling',
        url: Wrestling,
      },
    ],
  },
  {
    id: 'recm4sOq6jbnX0Kzv',
    category: 'Technology & Media',
    url: TechnologyMedia,
    subCategories: [
      {
        id: 'recm4sOq6jbnX0Kzv',
        category: 'Any Technology & Media Program',
        url: TechnologyMedia,
      },
      {
        id: 'recMRntsbiJfN9zkG',
        category: 'Film & Video Production',
        url: FilmVideoProduction,
      },
      {
        id: 'recAJLoVGQzzmcJlD',
        category: 'Robotics & Hardware Engineering',
        url: RoboticsHardwareEngineering,
      },
      {
        id: 'recN2mKgQQVjFuq7O',
        category: 'Social Media',
        url: SocialMedia,
      },
      {
        id: 'recxpukGMWKKgnyVz',
        category: 'UX/UI Design',
        url: UXUIDesign,
      },
      {
        id: 'recIiNWsU0wAeFurL',
        category: 'Website Design & Development',
        url: WebsiteDesignDevelopment,
      },
    ],
  },
  {
    id: 'rec9c7KPaHxrXYUVq',
    category: 'Science & Engineering',
    url: ScienceEngineering,
    subCategories: [
      {
        id: 'rec9c7KPaHxrXYUVq',
        category: 'Any Science & Engineering Program',
        url: ScienceEngineering,
      },
      {
        id: 'recjgNw14A10CeKYX',
        category: 'Architecture',
        url: Architecture,
      },
      {
        id: 'reczrUW0ezuRuwdMz',
        category: 'Astronomy',
        url: Astronomy,
      },
      {
        id: 'recRcdJDmYBDCR0mz',
        category: 'Astrophysics',
        url: Astrophysics,
      },
      {
        id: 'recYVGWRqU7IrtVk0',
        category: 'Biology',
        url: Biology,
      },
      {
        id: 'recbXzVYyezfKHdlw',
        category: 'Chemistry',
        url: Chemistry,
      },
      {
        id: 'recav9uNHwA6jS8SC',
        category: 'Engineering',
        url: Engineering,
      },
      {
        id: 'recHoamI5Hbk4xqUW',
        category: 'Mathematics',
        url: Mathmatics,
      },
      {
        id: 'recIpkY860PLFDDlq',
        category: 'Physics',
        url: Physics,
      },
      {
        id: 'recAJLoVGQzzmcJlD',
        category: 'Robotics & Hardware Engineering',
        url: RoboticsHardwareEngineering,
      },
    ],
  },
  {
    id: 'recqfw3pz5bcVv3Sz',
    category: 'Cultural & Advocacy Programs',
    url: CulturalAdvocate,
    subCategories: [
      {
        id: 'recqfw3pz5bcVv3Sz',
        category: 'Any Cultural & Advocacy Program',
        url: CulturalAdvocate,
      },
      {
        id: 'rec4YCztP1YgGkFdR',
        category: 'African Cultures',
        url: AfricanCultures,
      },
      {
        id: 'recVhDE1JTM5vu9ef',
        category: 'Asian Cultures',
        url: AsianCultures,
      },
      {
        id: 'recSsfkjBChVEc338',
        category: 'Black American Cultures',
        url: BlackAmericanCultures,
      },
      {
        id: 'rec7D3Xxm7l7Ii6j7',
        category: 'Diversity & Inclusion',
        url: DiversityAndInclusion,
      },
      {
        id: 'rec3W9SZbbK3a11sC',
        category: 'European Cultures',
        url: EuropeanCulture,
      },
      {
        id: 'rec4GHBYnG5yUH5ND',
        category: 'Latinx Cultures',
        url: LatinxCultures,
      },
      {
        id: 'recOufPcd2MFKjgP2',
        category: 'LGBTQ+',
        url: Lgbtq,
      },
      {
        id: 'rec4wk87YHBeyRyhT',
        category: 'Racial Equity',
        url: RacialEquity,
      },
    ],
  },
  {
    id: 'recrUYyZMlXWXhSDz',
    category: 'Business & Finance Programs',
    url: BusinessFinance,
    subCategories: [
      {
        id: 'recrUYyZMlXWXhSDz',
        category: 'Any Business & Finance Program',
        url: BusinessFinance,
      },
      {
        id: 'recd5Or4itVIY4jE8',
        category: 'Accounting',
        url: Accounting,
      },
      {
        id: 'rec5qFPwc670JbEuj',
        category: 'Business',
        url: Business,
      },
      {
        id: 'rec6PaJ1kh6P243UE',
        category: 'Entrepreneurship',
        url: Entrepreneurship,
      },
      {
        id: 'rec8xzxRuWOSIaLlD',
        category: 'Economics',
        url: Economics,
      },
      {
        id: 'recp5f2oUcSZDCSHo',
        category: 'Financial Literacy',
        url: FinancialLiteracy,
      },
      {
        id: 'recC4n3Nw6jhsCTFN',
        category: 'Leadership',
        url: Leadership,
      },
      {
        id: 'recc6eE3xNwdTCDzB',
        category: 'Management',
        url: Management,
      },
    ],
  },
  {
    id: 'recYXhfy9nxvxESi1',
    category: 'Arts Programs',
    url: Arts,
    subCategories: [
      {
        id: 'recXKTMCMHVGtq28P',
        category: 'Any Arts & Crafts Program',
        url: AnyArtsCrafts,
      },
      {
        id: 'recuWK1tSltrCWFsq',
        category: 'Ceramics',
        url: Ceramics,
      },
      {
        id: 'recttM12xBN54ei9N',
        category: 'Creative Writing',
        url: CreativeWriting,
      },
      {
        id: 'recmXXJdQGtcKt0PS',
        category: 'Digital Art & Graphic Design',
        url: DigitalArtsGraphicDesign,
      },
      {
        id: 'recVRtTc9bIS0vELX',
        category: 'Fashion',
        url: Fashion,
      },
      {
        id: 'recMRntsbiJfN9zkG',
        category: 'Film & Video Production',
        url: FilmVideoProduction,
      },
      {
        id: 'recMV3p7rNjhanFdM',
        category: 'Crafts & Jewelry Making',
        url: JewelryMaking,
      },
      {
        id: 'recQYgbmUjlOn8ccx',
        category: 'Painting',
        url: Painting,
      },
      {
        id: 'recWwquSBjzSUO3oR',
        category: 'photography',
        url: photography,
      },
      {
        id: 'recggzr93g8R1jIsF',
        category: 'Poetry',
        url: Poetry,
      },
      {
        id: 'recTMLiOqDr99DcBx',
        category: 'Script Writing',
        url: ScriptWriting,
      },
      {
        id: 'recFGgA9OJFNKCPpH',
        category: 'Sculpting',
        url: Sculpting,
      },
      {
        id: 'recDPmdH8q3ks5VyM',
        category: 'Sewing & Knirtting',
        url: SewingKnirtting,
      },
      {
        id: 'reccYp3Ss4jmhkUPC',
        category: 'Band',
        url: Band,
      },
      {
        id: 'recTUN1FHyNbsqaCU',
        category: 'Choir',
        url: Choir,
      },
      {
        id: 'rectaXCWD6trG7BmY',
        category: 'Jazz Band',
        url: JazzBand,
      },
      {
        id: 'reci4anB0OYb7nFPU',
        category: 'Music Production',
        url: MusicProduction,
      },
      {
        id: 'rec02K4HK6yI7VeX0',
        category: 'Orchestra',
        url: Orchestra,
      },
      {
        id: 'recH1NYF4fEe8iPR3',
        category: 'Woodworking',
        url: WoodWorking,
      },
      {
        id: 'recpM19X5fVFwswAv',
        category: 'Acting',
        url: Acting,
      },
      {
        id: 'rec7yfsQ4stFkoOzj',
        category: 'Comedy',
        url: Comedy,
      },
      {
        id: 'recQsOZWB31OA3Ibm',
        category: 'Dance',
        url: Dance,
      },
      {
        id: 'reci66852xkOMGcq4',
        category: 'Hip Hop / Rap',
        url: HipHopRap,
      },
      {
        id: 'reclcSZIHnGGtghoi',
        category: 'Spoken Word',
        url: SpokenWord,
      },
      {
        id: 'recoNX3PFXaBDc6YF',
        category: 'Theater',
        url: Theatre,
      },
    ],
  },
  {
    id: 'rec06bCrJgBEJaAC7',
    category: 'STEM',
    url: Stem,
    subCategories: [
      {
        id: 'rec06bCrJgBEJaAC7',
        category: 'Any STEM Program',
        url: Stem,
      },
      {
        id: 'recRcdJDmYBDCR0mz',
        category: 'Astrophysics',
        url: Astrophysics,
      },
      {
        id: 'reczrUW0ezuRuwdMz',
        category: 'Astronomy',
        url: Astronomy,
      },
      {
        id: 'recYVGWRqU7IrtVk0',
        category: 'Biology',
        url: Biology,
      },
      {
        id: 'recbXzVYyezfKHdlw',
        category: 'Chemistry',
        url: Chemistry,
      },
      // {
      //   id: 'recoNX3PFXaBDc6YF',
      //   category: 'Theater',  //Coding & Software missing
      //   url: Chemistry,
      // },
      {
        id: 'recav9uNHwA6jS8SC',
        category: 'Engineering',
        url: Engineering,
      },
      {
        id: 'recMRntsbiJfN9zkG',
        category: 'Film & Video Production',
        url: FilmVideoProduction,
      },
      {
        id: 'recp5f2oUcSZDCSHo',
        category: 'Financial Literacy',
        url: FinancialLiteracy,
      },
      {
        id: 'recHoamI5Hbk4xqUW',
        category: 'Mathematics',
        url: Mathmatics,
      },
      {
        id: 'recIpkY860PLFDDlq',
        category: 'Physics',
        url: Physics,
      },
      {
        id: 'recAJLoVGQzzmcJlD',
        category: 'Robotics & Hardware Engineering',
        url: RoboticsHardwareEngineering,
      },
      {
        id: 'recxpukGMWKKgnyVz',
        category: 'UI/UX Design',
        url: UXUIDesign,
      },
      {
        id: 'recIiNWsU0wAeFurL',
        category: 'Website Design & Development',
        url: WebsiteDesignDevelopment,
      },
    ],
  },
  {
    id: 'rec3GzZazLMXl55vJ',
    category: 'Academic Programs',
    url: Academic,
    subCategories: [
      {
        id: 'rec3GzZazLMXl55vJ',
        category: 'Any Academic Program',
        url: Academic,
      },
      {
        id: 'recmfeigYIuTcJi57',
        category: 'Debate',
        url: Debate,
      },
      {
        id: 'recJnQdPpjZuPEleT',
        category: 'National Honor Society',
        url: NationalHonorSociety,
      },
      {
        id: 'recohycODFGqsBWdO',
        category: 'Pentathalon',
        url: Pentathalon,
      },
      {
        id: 'recje2DiXbvfUH8tM',
        category: 'Speech',
        url: Speech,
      },
      {
        id: 'rec2wXi7NmPkiJjBC',
        category: 'Tutoring',
        url: Tutoring,
      },
      {
        id: 'recS8M9ZC1K7QODoz',
        category: 'Ethics',
        url: Ethics,
      },
      {
        id: 'reciXYPt5y7OL41zy',
        category: 'Spell & Quiz Bowls',
        url: SpellQuizBowl,
      },
      {
        id: 'recOvswi5H4tF4zak',
        category: 'Philosophy',
        url: Philosophy,
      },
    ],
  },
  {
    id: 'recx8l6NmwVxvuB5n',
    category: 'Language Arts',
    url: LanguageArts,
    subCategories: [
      {
        id: 'recx8l6NmwVxvuB5n',
        category: 'Any Language Arts Program',
        url: LanguageArts,
      },
      {
        id: 'recggzr93g8R1jIsF',
        category: 'Poetry',
        url: Poetry,
      },
      {
        id: 'recP05jQIApez2hwB',
        category: 'Song Writing',
        url: SongWriting,
      },
      {
        id: 'recttM12xBN54ei9N',
        category: 'Creative Writing',
        url: CreativeWriting,
      },
      {
        id: 'recTMLiOqDr99DcBx',
        category: 'Script Writing',
        url: ScriptWriting,
      },
      {
        id: 'reclC5puPfFtp8tyJ',
        category: 'Journalism',
        url: Journalism,
      },
      {
        id: 'rec6R1IfiPLHkkqHb',
        category: 'Reading',
        url: Reading,
      },
    ],
  },
  // {
  //   id: 'cat_14',
  //   category: 'Social Emotional Learning',
  //   url: SocialEmotionalLearning,
  //   subCategories: [],
  // },
  {
    id: 'recXKTMCMHVGtq28P',
    category: 'Arts & Crafts',
    url: AnyArtsCrafts,
    subCategories: [
      {
        id: 'recXKTMCMHVGtq28P',
        category: 'Any Arts & Crafts Program',
        url: AnyArtsCrafts,
      },
      {
        id: 'recbLn8Pd2siFQeL5',
        category: 'Blacksmithing',
        url:Blacksmithing,
      },
      {
        id: 'recuWK1tSltrCWFsq',
        category: 'Ceramics',
        url:Ceramics,
      },
      // {
      //   id: 'recXKTMCMHVGtq28P',
      //   category: 'Any Arts & Crafts Program',//Crafts & Jewellery missing
      //   url:'',
      // },
      {
        id: 'recmXXJdQGtcKt0PS',
        category: 'Digital Art & Graphic Design',
        url:DigitalArtsGraphicDesign,
      },

      {
        id: 'recVRtTc9bIS0vELX',
        category: 'Fashion',
        url:Fashion,
      },
      {
        id: 'recWwquSBjzSUO3oR',
        category: 'Photography',
        url:Photography,
      },
      {
        id: 'recQYgbmUjlOn8ccx',
        category: 'Painting',
        url:Painting,
      },
      {
        id: 'recFGgA9OJFNKCPpH',
        category: 'Sculpting',
        url:Sculpting,
      },
      {
        id: 'recDPmdH8q3ks5VyM',
        category: 'Sewing & Knitting',
        url:SewingKnirtting,
      },
      {
        id: 'recxpukGMWKKgnyVz',
        category: 'UI/UX Design',
        url:UXUIDesign,
      },
      {
        id: 'recH1NYF4fEe8iPR3',
        category: 'Woodworking',
        url:WoodWorking,
      },
    ],
  },
  {
    id: 'recYih6TraD86fqi7',
    category: 'Career & Character',
    url: AnyCareerAndCharacter,
    subCategories: [
      {
        id: 'recYih6TraD86fqi7',
        category: 'Any Career & Character Program',
        url: AnyCareerAndCharacter,
      },
      {
        id: 'recMoxaVYwypIWmzE',
        category: 'Collaboration',
        url: Collaboration,
      },
      {
        id: 'rec7XDP0ehOP5taJP',
        category: 'Communication',
        url: Communication,
      },
      {
        id: 'recjRW6oLORWXecCY',
        category: 'Creativity',
        url: Creativity,
      },
      {
        id: 'recYtD53TfBCCGW8M',
        category: 'Critical Thinking',
        url: CriticalThinking,
      },
      {
        id: 'recrZMtTjYvmjRGf1',
        category: 'Flexibility & Resiliance',
        url: FlexibilityAndResiliance,
      },
      {
        id: 'recMsrgLihr4rIRXk',
        category: 'Initiative',
        url: Initiative,
      },
      {
        id: 'recBYR9MLgaX4LCsS',
        category: 'Productivity',
        url: Productivity,
      },
      {
        id: 'rec7jhL11R72bjmIw',
        category: 'Social Skills',
        url: SocialSkills,
      },
      {
        id: 'recC4n3Nw6jhsCTFN',
        category: 'Leadership',
        url: Leadership,
      },
    ],
  },
];
