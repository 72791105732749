import React, { useState } from 'react';

import Categories from './Categories';
import useActivityStore from '../../store/activityStore';

const ActivityRequest = () => {
  const { selected, setSelected, setAllSubCategories, activeCategory, setActiveCategory, activeIndex, setActiveIndex } =
    useActivityStore();

  const handleSelection =
    (data, isDeselect = false) =>
    () => {
      if (activeIndex === 0 && !isDeselect) {
        if (data.subCategories?.length === 0) return;
        const existingIndex = selected.findIndex((obj) => obj.id === data.id);
        if (existingIndex !== -1 && isDeselect) {
          const clone = [...selected];
          clone.splice(existingIndex, 1);
          setSelected([...clone]);
          setActiveIndex(0);
          const allSubCategorySelected = clone.flatMap((cat) => cat.subCategories);
          setAllSubCategories([...allSubCategorySelected]);
        } else {
          setSelected([...selected, { ...data, subCategories: [] }]);
          setActiveCategory(data);
          setActiveIndex(1);
        }
      } else {
        const activeCat = selected.find((obj) => obj.id === activeCategory.id);
        const subCategories = activeCat.subCategories || [];
        const existingIndex = subCategories.findIndex((obj) => obj.id === data.id);
        if (existingIndex !== -1) {
          const clone = [...subCategories];
          clone.splice(existingIndex, 1);
          activeCat.subCategories = clone;
        } else {
          activeCat.subCategories = [...subCategories, data];
        }
        const allSubCategorySelected = selected.flatMap((cat) => cat.subCategories);
        setAllSubCategories([...allSubCategorySelected]);
        const index = selected.findIndex((obj) => obj.id === activeCategory.id);
        const cloneSelected = [...selected];
        cloneSelected[index] = activeCat;
        setSelected(cloneSelected);
      }
    };

  return (
    <Categories
      selected={selected}
      activeCategory={activeCategory}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      handleSelection={handleSelection}
    />
  );
};

export default ActivityRequest;
