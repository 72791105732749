import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import softwareIcon from 'assets/icons/softwareIcon.svg';
import programsIcon from 'assets/icons/programsIcon.svg';
import fundingIcon from 'assets/icons/fundingIcon.svg';
import useActivityStore from '../../../store/activityStore';

import useStyles from './styles';

const cardContent = [
  {
    id: 1,
    image: softwareIcon,
    title: 'Software',
    detail: 'We streamline scheduling, registration, payment, and communication.',
  },
  {
    id: 2,
    image: programsIcon,
    title: 'Programs',
    detail: 'We curate vetted program providers that fit the needs and aspirations of your students.',
  },
  {
    id: 3,
    image: fundingIcon,
    title: 'Funding',
    detail: 'We make programs more accessible through grants, scholarships, and sponsorships.',
  },
];

const Success = () => {
  const history = useHistory();
  const classes = useStyles();
  const { success } = useActivityStore()

  useEffect(() => {
    if (!success) {
      history.push('/activity-request');
    }
  }, [success]);

  return (
    <Container className={classes.wrapper} maxWidth="xl">
      <Grid justifyContent="center" className={classes.thankYou} container={true}>
        <Typography variant="h4">Thank you for submitting your request!</Typography>
      </Grid>
      <Container className={classes.whatsNext}>
        <Typography variant="body1">What's next?</Typography>
        <Typography variant="body2">
          Our team of dedicated education consultants will be searching our program database to find programs that match
          your request. We will be in touch within five business days.
        </Typography>
      </Container>
      <Container maxWidth="md">
        <Grid container={true} className={classes.activities}>
          <Typography color="primary" variant="h5">
            Need to manage existing activities?
          </Typography>
          <Grid spacing={3} className={classes.cards} container={true}>
            {cardContent.map((data, i) => {
              return (
                <Grid key={i} sm={4} lg={4} xl={4} md={4} className={classes.card} item={true}>
                  <img src={data.image} alt={data.title}  />
                  <Typography variant="h6">{data.title}</Typography>
                  <Typography variant="body1">{data.detail}</Typography>
                </Grid>
              );
            })}
          </Grid>
            <a href='https://afterschoolhq.com/signup/Welcome' >
              <Button variant="contained" color="primary">
              Create an Account
              </Button>
            </a>
        </Grid>
      </Container>
    </Container>
  );
};

export default Success;
