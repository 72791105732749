import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProgramForm from 'pages/ActivityRequest/ProgramForm';
import Success from 'pages/ActivityRequest/Success';
import ActivityRequest from 'pages/ActivityRequest';
import Page from 'components/Page';
import ActivityRequestPage from 'components/ActivityRequestPage';
import Dashboard from 'pages/Dashboard';
import InProgress from 'pages/InProgress';
import Intro from 'pages/Journal/Intro';
import Survey from 'pages/Journal/Survey';
import UserProfile from 'pages/UserProfile';
import Completed from 'pages/Journal/Survey/Completed';
import Feed from 'pages/Feed';
import Onboarding from 'pages/Onboarding';
import useSignUpStore from 'store/signupStore';
import CircularProgress from './components/CircularProgress';

const App = () => {
  const { authenticated, setLocalStorageValues, loadingData } = useSignUpStore();

  const renderComponentWithSidebar = (Component) => () => <Page Component={Component} />;

  const renderActivityRequestPage = (Component) => () => <ActivityRequestPage Component={Component} />;

  useEffect(() => {
    setLocalStorageValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // need to replace with loader once we have design.
  if (loadingData) return <CircularProgress />;

  return (
    <>
      {authenticated ? (
        <Switch>
          <Route path="/user-profile" exact render={renderComponentWithSidebar(UserProfile)} />
          <Route path="/journal/:journalId/completed" exact render={renderComponentWithSidebar(Completed)} />
          <Route path="/survey/:surveyId/:questionId" exact render={renderComponentWithSidebar(Survey)} />
          <Route path="/survey/:surveyId" exact render={renderComponentWithSidebar(Intro)} />
          <Route path="/feed" exact render={renderComponentWithSidebar(Feed)} />
          <Route path="/survey" exact render={renderComponentWithSidebar(InProgress)} />
          <Route path="/" exact render={renderComponentWithSidebar(Dashboard)} />
          <Redirect to="/" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/activity-request" exact component={renderActivityRequestPage(ActivityRequest)} />
          <Route path="/activity-request/program-form" exact component={renderActivityRequestPage(ProgramForm)} />
          <Route path="/activity-request/success" exact component={renderActivityRequestPage(Success)} />
          <Route path="/onboarding/login" exact component={Onboarding} />
          <Route path="/onboarding/signup" exact component={Onboarding} />
          <Route path="/onboarding/signup/:inviteCode" exact component={Onboarding} />
          <Redirect to="/onboarding/login" />
        </Switch>
      )}
    </>
  );
};

export default App;
