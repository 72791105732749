import create from 'zustand';
import airtable from 'http/airtable';

const useActivityStore = create((set, get) => ({
  activeIndex:0,
  activeCategory: {},
  errorMessage: '',
  selected: [],
  success: false,
  selectedOptions: [],
  allSubCategories: [],
  inputErrorState: {
    schoolNameErrorState: false,
    numberOfStudentsErrorState: false,
    schoolStateErrorState: false,
    schoolCityErrorState: false,
    nameErrorState: false,
    phoneNumberErrorState: false,
    emailErrorState: false,
    studentsServedErrorState:false
  },
  inputDetails: {
    studentsServed:'',
    schoolName: '',
    numberOfStudents: '',
    schoolState: '',
    schoolCity: '',
    name: '',
    phoneNumber: '',
    email: '',
    additionalComment: '',
    permission: false,
    radioButtonValue: '',
  },
  activityTags: [],
  activityTypes: [],
  activityStates: [],
  setActiveIndex: (data) => set({activeIndex:data}),
  setAllSubCategories: (data) => set({ allSubCategories: data }),
  setSelectedOptions: (data) => set({ selectedOptions: data }),
  setInputErrorState: (data) => set({ inputErrorState: data }),
  setInputDetails: (data) => set({ inputDetails: data }),
  setSelected: (data) => set({ selected: data }),
  setActiveCategory: (data) => {
    set({ activeCategory: data });
  },
  handleSelectDelete: (id) => {
    const { selected } = get();
    const currentCatIndex = selected.findIndex((cat) => cat.id === id);
    set({
      selected: [...selected.slice(0, currentCatIndex), ...selected.slice(currentCatIndex + 1)],
    });
  },
  handleFormDataSubmit: (formData) => {
    airtable('Staff Activity Requests')
      .create(formData)
      .then((data) => {
        set({
          success: true,
        });
      })
      .catch((error) => {
        set({ errorMessage: error.message });
      });
  },

  getActivityTypes: () => {
    const allTags = [];
    const allType = [];
    const allStates = [];
    airtable('Activity Tags')
      .select()
      .eachPage((tags) => {
        tags.forEach((tag) => {
          allTags.push({
            fields: tag.fields,
            id: tag.id,
          });
        });
        set({
          activityTags: allTags,
        });
      });

    airtable('Activity Types')
      .select()
      .eachPage((types, fetchNextPage) => {
        types.forEach((type) => {
          allType.push({
            name: type.fields.Name,
            id: type.id,
          });
        });
        set({
          activityTypes: allType,
        });

        fetchNextPage();
      });
    airtable('States')
      .select()
      .eachPage((states) => {
        states.forEach((state) => {
          allStates.push({
            name: state.fields.Name,
            id: state.id,
          });
        });
        set({
          activityStates: allStates,
        });
      });
  },
}));

export default useActivityStore;
