import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import cs from 'classnames';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { categories } from '../mock';
import useActivityStore from 'store/activityStore';
import IconlyCurvedArrowLeft from 'assets/icons/IconlyCurvedArrowLeft.svg';

const Categories = ({ selected, activeCategory, handleSelection }) => {
  const { setAllSubCategories, setSelected, activeIndex, setActiveIndex } = useActivityStore();
  const [countBtn, setCountBtn] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const checkIsSelected = (id) => {
    if (activeIndex === 0) {
      return !!selected.find((obj) => obj.id === id);
    } else {
      const cat = selected.find((obj) => obj.id === activeCategory?.id);
      if (!cat) {
        return false;
      }
      const subCats = cat.subCategories || [];
      return !!subCats.find((obj) => obj.id === id);
    }
  };

  const handleDeleteChips = (category, data) => () => {
    const activeCat = selected.find((obj) => obj.id === category.id);
    const subCategories = activeCat.subCategories || [];
    const existingIndex = subCategories.findIndex((obj) => obj.id === data.id);
    if (existingIndex !== -1) {
      const clone = [...subCategories];
      clone.splice(existingIndex, 1);
      activeCat.subCategories = clone;
    } else {
      activeCat.subCategories = [...subCategories, data];
    }
    const allSubCategorySelected = selected.flatMap((cat) => cat.subCategories);
    setAllSubCategories([...allSubCategorySelected]);
    const index = selected.findIndex((obj) => obj.id === category.id);
    const cloneSelected = [...selected];
    cloneSelected[index] = activeCat;
    setSelected(cloneSelected);
  };

  const handleDeselectCategory = (data, e) => {
    e.stopPropagation();
    if (activeIndex === 0) {
      const existingIndex = selected.findIndex((obj) => obj.id === data.id);
      if (existingIndex !== -1) {
        const clone = [...selected];
        clone.splice(existingIndex, 1);
        setSelected([...clone]);
        setActiveIndex(0);
        const allSubCategorySelected = clone.flatMap((cat) => cat.subCategories);
        setAllSubCategories([...allSubCategorySelected]);
      } else {
        setSelected([...selected, { ...data, subCategories: [] }]);
        setActiveIndex(0);
      }
    }
  };

  const totalSelectedSubcategories = selected.flatMap((data) => data.subCategories).length;

  return (
    <Container className={classes.activityWrapper} maxWidth="xl">
      <Grid container={true}>
        <Grid md={10} item={true}>
          <Grid className={classes.upperContainerMobile} container={true}>
            {totalSelectedSubcategories !== 0 && (
              <div onClick={() => setCountBtn(!countBtn)} className={classes.counter}>
                {totalSelectedSubcategories}
              </div>
            )}
            <Grid container={true}>
              {activeIndex === 0 && (
                <Grid item={true} className={classes.cardHeader}>
                  <Typography variant="h4">Request local programs for your school.</Typography>
                  <Typography variant="caption">
                    Click on a category to see additional options - we'll keep track of your list for you in the
                    sidebar.
                  </Typography>
                </Grid>
              )}
              {activeIndex === 1 && (
                <Grid item={true} className={classes.cardHeader}>
                  <div onClick={() => setActiveIndex(0)} className={classes.prevContent}>
                    <img alt="arrow" src={IconlyCurvedArrowLeft} />
                    <p>Return to All Categories</p>
                  </div>
                  <Typography variant="h4">{activeCategory.category}</Typography>
                  <Typography variant="caption">Select as many sub-categories as you'd like.</Typography>
                </Grid>
              )}
            </Grid>
            <Grid item={true}>
              <Container className={classes.categoriesContainer} maxWidth="xl">
                {activeIndex === 0 && (
                  <Grid className={classes.categoriesSection} spacing={3} container={true}>
                    {categories.map((data, i) => {
                      return (
                        <Grid
                          key={i}
                          onClick={handleSelection(data)}
                          className={classes.cardActionArea}
                          xs={6}
                          md={4}
                          item={true}
                        >
                          <CardActionArea>
                            <CardContent className={classes.cardContent}>
                              {checkIsSelected(data.id) &&
                                selected.find((el) => el.id === data.id).subCategories.length !== 0 && (
                                  <div className={classes.active}>
                                    <div></div>
                                    <div className={classes.selected}>
                                      <Typography style={{ color: '#fff' }} variant="button">
                                        {selected.find((el) => el.id === data.id).subCategories.length}{' '}
                                        <span className={classes.selectedText}>Selected</span>
                                      </Typography>
                                    </div>
                                    <div onClick={(e) => handleDeselectCategory(data, e)} className={classes.checkBox}>
                                      <CheckRoundedIcon />
                                    </div>
                                  </div>
                                )}
                              <div
                                className={cs(classes.imgWraper, {
                                  [classes.imgBorder]:
                                    checkIsSelected(data.id) &&
                                    selected.find((el) => el.id === data.id).subCategories.length !== 0,
                                })}
                              >
                                <div className={classes.overlay} />
                                <CardMedia className={classes.cards} component="img" alt="image" image={data.url} />
                                <Typography className={classes.overlayTitle} gutterBottom variant="h6" component="h2">
                                  {data.category}
                                </Typography>
                              </div>
                            </CardContent>
                          </CardActionArea>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {activeIndex === 1 && (
                  <Grid className={classes.categoriesSection} spacing={3} container={true}>
                    {activeCategory?.subCategories?.map((data, i) => {
                      return (
                        <Grid
                          key={i}
                          onClick={handleSelection(data)}
                          className={classes.cardActionArea}
                          xs={6}
                          md={4}
                          item={true}
                        >
                          <CardActionArea>
                            <CardContent className={classes.cardContent2}>
                              <div className={classes.active2}>
                                <div>
                                  <div
                                    className={cs(classes.checkBox2, {
                                      [classes.isChecked2]: checkIsSelected(data.id),
                                    })}
                                  >
                                    <CheckRoundedIcon />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={cs(classes.imgWraper, {
                                  [classes.imgBorder2]: checkIsSelected(data.id),
                                })}
                              >
                                <div className={classes.overlay} />
                                <CardMedia className={classes.cards} component="img" alt="image" image={data.url} />
                                <Typography className={classes.overlayTitle} gutterBottom variant="h6" component="h2">
                                  {data.category}
                                </Typography>
                              </div>
                            </CardContent>
                          </CardActionArea>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Container>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={cs(classes.sideContent, { [classes.mobileBottomContent]: countBtn })}
          xs={12}
          sm={12}
          md={2}
          item={true}
        >
          <Container className={classes.sideContainer}>
            <Grid className={classes.innerContent} container={true}>
              <Grid
                className={cs(classes.sideContentHeader, { [classes.sideContentHeaderShow]: countBtn })}
                item={true}
              >
                <br />
                <Typography className={classes.sideTitle} variant="h6">
                  Find the perfect activities for your students
                </Typography>
                <div className={classes.chipsWrapper}>
                  <div className={classes.chips}>
                    {selected.map((cat) => {
                      return cat.subCategories.map((data, i) => {
                        return (
                          <Button key={i} variant="contained">
                            <div onClick={handleDeleteChips(cat, data)} className={classes.chipContent}>
                              <div>{data.category}</div>
                              <div className="close">⨯</div>
                            </div>
                          </Button>
                        );
                      });
                    })}
                  </div>
                </div>
              </Grid>
              <Grid className={classes.preferenceBtn} item={true}>
                <Button onClick={() => history.push('/activity-request/program-form')} variant="contained">
                  Continue to Preferences
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Categories;
