import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: '0',
      background: '#fff',
      width: '100%',
      zIndex: '999',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  mainImage: {
    '& img': {
      height: '44px',
      marginLeft: '22px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        height: '22px',
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingTop: '10px',
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px',
      paddingBottom: '5px',
    },
    '& h4': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        marginBottom: '-10px',
      },
    },
    '& span': {
      fontSize: '11px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '7px',
      },
    },
  },
  lineBreak: {
    margin: '0',
    border: `1px solid ${theme.palette.primary.light}`,
  },
}));

export default useStyles;
